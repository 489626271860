import { apiService, FeatureStateVM } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'
import { useQuery, useQueryClient } from 'react-query'

const getState = async (featureId?: string) => {
  const response = await apiService.features_GetFeatureStates(featureId || '')
  return response[0]
}

export function useFeatureState(featureId?: string) {
  const queryClient = useQueryClient()
  return useQuery([SgQueryKey.featureState, featureId], () => getState(featureId), {
    enabled: !!featureId,
    initialData: () => {
      const featuresStates = queryClient.getQueryData<FeatureStateVM[]>(SgQueryKey.featureState)
      const featureState = featuresStates?.find((f) => f.id === featureId)
      return featureState
    },
  })
}
