import { ActionButton, getTheme, IButtonStyles, IColumn, IIconProps, PrimaryButton } from '@fluentui/react'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { getFormattedDate } from 'src/utils'
import { Parser, FieldInfo } from '@json2csv/plainjs'
import _ from 'lodash'
import { useDownloadFile } from 'src/hooks'
import { DownloadButtonType } from 'src/enums'
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js'

export interface DownloadReportProps {
  columns?: IColumn[]
  data?: any[]
  fileTitle?: string
  text?: string
  buttonType?: DownloadButtonType
  disabled?: boolean
  iconProps?: IIconProps
  styles?: IButtonStyles
  onReportDownload?: (report: string, fileName: string) => void
  performOnlyOnReportDownload?: boolean
  includeDateInFilename?: boolean
  onClickLoadData?: () => Promise<any[]>
}

const theme = getTheme()

const downloadIcon: IIconProps = { iconName: 'Download' }
const buttonStyles: IButtonStyles = {
  flexContainer: { flexDirection: 'row-reverse', color: theme.palette.blue },
}

const mapToFields = (columns: IColumn[]): FieldInfo<any>[] => {
  const renderFunctions = new Set()
  const fields =
    columns
      ?.filter((column) => {
        return column.columnActionsMode === undefined
      })
      .map((column) => {
        return {
          label: column.name,
          value: (row) => {
            if (column.onRender) {
              if (renderFunctions.has(column.key)) {
                return column.onRender(row)
              } else {
                const item = column.onRender(row)
                if (_.isString(item)) {
                  renderFunctions.add(column.key)
                  return column.onRender(row)
                }
              }
            }
            if (column.fieldName) {
              return row[column.fieldName]
            }
            return ''
          },
        } as FieldInfo<any>
      }) || []
  return fields
}

const createReport = (reportColumns: IColumn[] | undefined, reportData: any[]) => {
  const fields = reportColumns ? mapToFields(reportColumns) : undefined
  const json2csvParser = fields ? new Parser({ fields }) : new Parser()
  return json2csvParser.parse(reportData)
}

export const DownloadReport: FunctionComponent<DownloadReportProps> = ({
  columns,
  data,
  fileTitle = 'report',
  text = 'Download Report',
  buttonType = DownloadButtonType.ACTION_BUTTON,
  disabled = false,
  iconProps = downloadIcon,
  styles = buttonStyles,
  onReportDownload,
  onClickLoadData,
  performOnlyOnReportDownload = false,
  includeDateInFilename = true,
}) => {
  const [trackData, setTrackData] = useState<any>({ downloadCount: 0 })
  const downloadFile = useDownloadFile()
  const appInsights = useAppInsightsContext()
  const trackDownload = useTrackEvent(appInsights, 'DownloadReport', trackData)

  useEffect(() => {
    if (trackData?.downloadCount > 0) {
      trackDownload({ fileTitle })
    }
  }, [trackData, fileTitle, trackDownload])

  const downloadReport = useCallback(
    (reportTitle: string, csv: string, downloadFileFunc: (fileName: string, blob: Blob) => void) => {
      const blob = new Blob([csv], { type: `text/csv;charset=utf-8;` })
      downloadFileFunc(reportTitle, blob)
      setTrackData((currentValue) => {
        return { ...currentValue, downloadCount: currentValue.downloadCount + 1 }
      })
    },
    [setTrackData],
  )

  const onClick = useCallback(async () => {
    const dataToDownload = onClickLoadData ? await onClickLoadData() : data
    if (dataToDownload) {
      const report = createReport(columns, dataToDownload)
      const fileName = `${fileTitle}${includeDateInFilename ? `- ${getFormattedDate(new Date())}` : ''}.csv`
      if (!performOnlyOnReportDownload) {
        downloadReport(fileName, report, downloadFile)
      }

      if (onReportDownload) {
        onReportDownload(report, fileName)
      }
    }
  }, [
    columns,
    data,
    downloadFile,
    downloadReport,
    fileTitle,
    includeDateInFilename,
    onClickLoadData,
    onReportDownload,
    performOnlyOnReportDownload,
  ])

  if (!data?.length && !onClickLoadData) {
    return null
  }

  const btnProps = {
    onClick,
    text,
    disabled,
  }
  return buttonType == DownloadButtonType.PRIMARY_BUTTON ? (
    <PrimaryButton {...btnProps} />
  ) : (
    <ActionButton {...btnProps} iconProps={iconProps} styles={styles} />
  )
}
