import { useMutation, useQueryClient } from 'react-query'
import { apiService, CreateFeatureStateCommand, UpdateFeatureStateCommand, FeatureStateVM } from 'src/services/graphapi'
import { optimisticUpdateAndInvalidateQueries, SgQueryKey } from './Shared'

const saveFeatureState = async (featureId: string, command: CreateFeatureStateCommand) => {
  await apiService.features_CreateFeatureState(featureId, command)
}

const updateFeatureState = async (id: string, featureId: string, command: UpdateFeatureStateCommand) => {
  await apiService.features_UpdateFeatureState(id, featureId, command)
}

export function useSaveFeatureState() {
  const queryClient = useQueryClient()

  return useMutation(
    (command: UpdateFeatureStateCommand) =>
      !!command.id && !!command.featureId
        ? updateFeatureState(command.id, command.featureId, command)
        : saveFeatureState(command.featureId ? command.featureId : '', command),
    {
      onSuccess: (_data, _variables) => {
        optimisticUpdateAndInvalidateQueries<FeatureStateVM[]>(
          queryClient,
          SgQueryKey.featureState,
          (_queryKey, data) => {
            return data
          },
        )
        queryClient.invalidateQueries([SgQueryKey.featureState])
      },
    },
  )
}
