import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'
import { useQuery } from 'react-query'

const getSkus = async (featureId?: string) => {
  const response = await apiService.features_GetFeatureSkus(featureId || '')
  return response
}

export function useFeatureSkus(featureId?: string) {
  return useQuery([SgQueryKey.featureSkus, featureId], () => getSkus(featureId), {
    enabled: !!featureId,
  })
}
